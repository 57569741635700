/*:global(.adp-combine-layout-sider-children) {
  overflow: auto;
}

:global(.adp-combine-layout-sider-children::-webkit-scrollbar) {
    width: 4px;
}
:global(.adp-combine-layout-sider-children::-webkit-scrollbar-track) {
    background-color: rgba(#fff, 0.1);
    box-shadow: inset 0 1px 1px rgba(black, 0.1);
}
:global(.adp-combine-layout-sider-children::-webkit-scrollbar-thumb) {
    background-color: rgba(gray, 0.4);
    box-shadow: 0 1px 1px rgba(black, 0.1);
    border-radius: 2px;
}*/
._1Bz06dj3hgZCnzn5po71wL {
  width: 100%;
  /* shadow/basic */
  height: 88px;
  right: 0;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 10;
  display: flex;
  min-width: 1400px;
  justify-content: space-between;
}
._1Bz06dj3hgZCnzn5po71wL ._2Ml06nqsy4iX_xEAacD4zJ {
  display: flex;
}
._1xizOQmc4wwdmObtgh1vPJ {
  padding-left: 24px;
  padding-top: 30px;
}
._1xizOQmc4wwdmObtgh1vPJ img {
  vertical-align: top;
}
._1jJsKa_9wKjomn8-9Xj6I2 {
  padding-left: 54px;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL {
  display: inline-block;
  font-size: 15px;
  padding: 22px 30px 0 30px;
  color: #030303;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  height: 74px;
  box-sizing: border-box;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL ._1MHrD9j8HG0etsfUj3Sz1- {
  height: 30px;
  text-align: center;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL ._1MHrD9j8HG0etsfUj3Sz1- b {
  display: block;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL ._1MHrD9j8HG0etsfUj3Sz1- span {
  display: none;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._3NAgsc9d5E-ga0u0uFeHUK {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNSA1LjI4MDMxQzAuNSAyLjc5NTAxIDIuNTE0NzMgMC43ODAyNzMgNS4wMDAwNCAwLjc4MDI3M0gxNy44NTg0QzIwLjM0MzcgMC43ODAyNzMgMjIuMzU4NCAyLjc5NTAxIDIyLjM1ODQgNS4yODAzMVY3LjYyMDIzQzIyLjM1ODQgMTAuMTA1NSAyMC4zNDM3IDEyLjEyMDMgMTcuODU4NCAxMi4xMjAzSDAuNVY1LjI4MDMxWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzQ5ODZfMTE4MzYwKSIvPgo8cGF0aCBkPSJNNy41ODc2MSA5LjEyMDI3SDYuNjU3Nkw0LjU0MjU2IDUuODQyNzdWOS4xMjAyN0gzLjUwMDA1VjMuNzgwMjdINC40MzAwNkw2LjU0NTEgNy4wNTAyN1YzLjc4MDI3SDcuNTg3NjFWOS4xMjAyN1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xMi4zNDk3IDkuMTIwMjdIOC44MzIxNlYzLjc4MDI3SDEyLjM0OTdWNC43MTAyN0g5Ljg3NDY4VjUuOTYyNzdIMTEuOTgyMlY2Ljg5Mjc3SDkuODc0NjhWOC4xOTAyN0gxMi4zNDk3VjkuMTIwMjdaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTkuMzU4MyAzLjc4MDI3TDE3LjkyNTggOS4xMjAyN0gxNy4wNTU4TDE2LjA3MzMgNS44ODAyN0wxNS4wOTA4IDkuMTIwMjdIMTQuMjIwOEwxMi43OTU3IDMuNzgwMjdIMTMuODgzM0wxNC43MDgzIDcuMTQ3NzdMMTUuNjgzMyAzLjc4MDI3SDE2LjQ2MzNMMTcuNDM4MyA3LjE0Nzc3TDE4LjI3MDggMy43ODAyN0gxOS4zNTgzWiIgZmlsbD0id2hpdGUiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl80OTg2XzExODM2MCIgeDE9IjIwLjUiIHkxPSIyIiB4Mj0iMiIgeTI9IjEyIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGRDQzM0IiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkY4NTE0Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
  background-position: 75px 15px;
  background-repeat: no-repeat;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._2LL-z0P984VjtuVPTi2IQY {
  color: #296BEF;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._2LL-z0P984VjtuVPTi2IQY .h1hk8wjvdaZ1q-p1CienO {
  position: absolute;
  bottom: 0;
  height: 3px;
  left: 43%;
  right: 43%;
  background: #296BEF;
  border-radius: 1.5px;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL:hover,
._1jJsKa_9wKjomn8-9Xj6I2 ._2LL-z0P984VjtuVPTi2IQY {
  color: #296AEF;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL:hover ._1MHrD9j8HG0etsfUj3Sz1- b,
._1jJsKa_9wKjomn8-9Xj6I2 ._2LL-z0P984VjtuVPTi2IQY ._1MHrD9j8HG0etsfUj3Sz1- b {
  display: none;
}
._1jJsKa_9wKjomn8-9Xj6I2 ._1Y9y4l191qmBkpbkdpG5bL:hover ._1MHrD9j8HG0etsfUj3Sz1- span,
._1jJsKa_9wKjomn8-9Xj6I2 ._2LL-z0P984VjtuVPTi2IQY ._1MHrD9j8HG0etsfUj3Sz1- span {
  display: block;
}
._1Bz06dj3hgZCnzn5po71wL ._1h741I48NqROFFcmRabw5o {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
._1Bz06dj3hgZCnzn5po71wL ._1h741I48NqROFFcmRabw5o ._3JPd9pBz2919EXtoHSOL04 {
  height: 16px;
  border-left: 1px solid #CDCFD5;
  margin-left: 2px;
  margin-right: 2px;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz {
  padding-left: 25px;
  position: relative;
  cursor: pointer;
  color: #030303;
  margin-right: 20px;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz ._27lhVQewz6LR69ulIMelFx {
  position: absolute;
  left: 0;
  top: 5px;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz ._27lhVQewz6LR69ulIMelFx > div {
  display: inline-block;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz ._27lhVQewz6LR69ulIMelFx > span {
  display: none;
}
._1Bz06dj3hgZCnzn5po71wL ._3nZEX9Eteytcs3xYQwp7vD {
  color: #296AEF;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz:hover {
  color: #296AEF;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz:hover ._27lhVQewz6LR69ulIMelFx > div {
  display: none;
}
._1Bz06dj3hgZCnzn5po71wL .x6fzQwHVW22HCClSULVNz:hover ._27lhVQewz6LR69ulIMelFx > span {
  display: inline-block;
}
._1Bz06dj3hgZCnzn5po71wL .fz9zWql64tc8mK9vZGsUk {
  cursor: pointer;
  background-image: url(https://zhb-admin1-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/e66b9a30d71b1a0bf70fd030bf5dcfda.png);
  background-repeat: no-repeat;
  background-position: -5px 18px;
  background-size: 26px;
  padding-left: 20px;
}
._3WhAyvsUQ_mHwSM1oplzcL {
  height: calc(100vh - 145px);
}
._3WhAyvsUQ_mHwSM1oplzcL ._246__dSIF2mSNEEk96NR9u {
  margin-left: 24px;
  margin-right: 4px;
  font-size: 14px;
  padding-top: 12px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 186px);
}
._3WhAyvsUQ_mHwSM1oplzcL ._2yNpJzl1FIEF45EIBgTzfb {
  padding-left: 16px;
  padding-right: 4px;
  font-size: 14px;
  padding-top: 12px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 182px);
}
._3WhAyvsUQ_mHwSM1oplzcL ._3gZx_mAKFM4UPT4SFQdBg_ {
  height: 1px;
  width: auto;
  background-color: #DBDDE2;
  margin-left: 24px;
  margin-right: 24px;
}
._3WhAyvsUQ_mHwSM1oplzcL ._3wb1C-KmOAeTVMvthfKNIl {
  height: 1px;
  width: auto;
  background-color: #DBDDE2;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
  margin-top: 4px;
}
._1BRLae23TBi_fS5Adlr-vH {
  max-width: 240px;
}
._1BRLae23TBi_fS5Adlr-vH ._1ZCESHc_heew3BDd4RrFjY {
  padding-left: 5px;
  white-space: normal;
}
._1BRLae23TBi_fS5Adlr-vH ._1ZCESHc_heew3BDd4RrFjY ._10FGAu1lE9YoPY7KlUTGZ6 {
  color: #4D5869;
  font-size: 14px;
  font-weight: 400;
  margin-top: 7px;
  line-height: 20px;
}
._1BRLae23TBi_fS5Adlr-vH ._1ZCESHc_heew3BDd4RrFjY td {
  color: #868E9F;
  vertical-align: top;
  word-break: break-all;
  font-size: 12px;
  padding: 2px 0;
}
._1BRLae23TBi_fS5Adlr-vH ._1ZCESHc_heew3BDd4RrFjY tr > td:first-child {
  padding-right: 10px;
}
._1BRLae23TBi_fS5Adlr-vH .tea-list--option > li {
  font-size: 14px;
  padding: 6px 8px;
  color: #4D5869;
}
._1BRLae23TBi_fS5Adlr-vH .tea-list--option > li.is-disabled {
  cursor: pointer;
}
._1BRLae23TBi_fS5Adlr-vH .tea-dropdown-box__footer {
  padding-top: 3px;
}
._1BRLae23TBi_fS5Adlr-vH .tea-dropdown-box__footer .tea-list--option > li:hover {
  background-color: #EEF4FE;
  color: #296BEF;
}
._1rzTqp4PWQlsNiaMF6hlvJ {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIxMDI4IiB2aWV3Qm94PSIwIDAgMTQ0MCAxMDI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjI1IiBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzQ5ODZfMTE4MjkyKSI+CjxlbGxpcHNlIGN4PSIxMzE4IiBjeT0iLTkiIHJ4PSIzNTciIHJ5PSIzNTkuNSIgZmlsbD0iI0ZGQTIwMCIgZmlsbC1vcGFjaXR5PSIwLjU2Ii8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZl80OTg2XzExODI5MiIgeD0iLTM5IiB5PSItMTM2OC41IiB3aWR0aD0iMjcxNCIgaGVpZ2h0PSIyNzE5IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9InNoYXBlIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjUwMCIgcmVzdWx0PSJlZmZlY3QxX2ZvcmVncm91bmRCbHVyXzQ5ODZfMTE4MjkyIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-position: right top;
}
._2hivSXFiDHCBrdM0-PTfLp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#root {
  background: linear-gradient(166deg, #EBF0FA 0%, #F4F5F6 36.69%);
}
.adp-combine-layout-sider {
  background: none !important;
}
.adp-combine-menu {
  background: none !important;
}
.adp-combine-menu-submenu-title {
  padding-left: 6px !important;
  padding-right: 20px !important;
}
.adp-combine-menu-submenu-title > div, .adp-combine-menu-item > div {
  width: 22px;
  padding-top: 5px;
  padding-left: 3px;
}
.sidder-menu--large.adp-combine-menu-inline > .adp-combine-menu-item {
  height: 56px;
  line-height: 56px;
}
.sidder-menu--large.adp-combine-menu:not(.adp-combine-menu-horizontal) .adp-combine-menu-item-selected::before {
  top: 17px;
  height: 20px;
}
.sidder-menu--large .adp-combine-menu-item > div {
  width: auto;
  padding: 10px 12px 10px 0;
  box-sizing: border-box;
  height: 100%;
}
.adp-combine-menu-submenu-popup {
  background-color: #fff !important;
}
.adp-combine-menu-submenu-expand-icon, .adp-combine-menu-submenu-arrow {
  right: 5px !important;
}
.adp-combine-menu-item {
  padding-left: 6px !important;
}
.adp-combine-menu-sub.adp-combine-menu-inline > .adp-combine-menu-item {
  padding-left: 26px !important;
}
.adp-combine-menu-item::after {
  border-right: none !important;
}
.adp-combine-menu:not(.adp-combine-menu-horizontal):not(.adp-combine-menu-inline-collapsed) .adp-combine-menu-item-selected {
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.72);
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.8) 100%);
  /* shadow/01 */
  box-shadow: 0px 4px 12px 0px rgba(65, 77, 96, 0.1);
}
.adp-combine-menu:not(.adp-combine-menu-horizontal):not(.adp-combine-menu-inline-collapsed) .adp-combine-menu-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  height: 12px;
  width: 2px;
  background-color: #296BEF;
  transition: all 0.4s ease;
  opacity: 0;
  transform: scaleY(0);
}
.adp-combine-menu:not(.adp-combine-menu-horizontal):not(.adp-combine-menu-inline-collapsed) .adp-combine-menu-item-selected::before {
  transform: scale(1);
  opacity: 1;
}
.adp-combine-menu-inline-collapsed:not(.adp-combine-menu-horizontal) .adp-combine-menu-item-selected {
  background: none !important;
}
.adp-combine-menu-item:hover {
  color: #296BEF;
}
.adp-combine-menu-inline {
  border-right: none !important;
}
._3Ii5RCcPfygD1YcvmNIg-t {
  width: 24px;
  height: 24px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDZIM1Y0LjVIMjFWNloiIGZpbGw9IiMwMzAzMDMiLz4KPHBhdGggZD0iTTEzLjUgMTJIM1YxMC41SDEzLjVWMTJaIiBmaWxsPSIjMDMwMzAzIi8+CjxwYXRoIGQ9Ik0zIDE4SDIxVjE2LjVIM1YxOFoiIGZpbGw9IiMwMzAzMDMiLz4KPHBhdGggZD0iTTE2LjUgMTEuMjVMMjEgOUwyMSAxMy41TDE2LjUgMTEuMjVaIiBmaWxsPSIjMDMwMzAzIi8+Cjwvc3ZnPgo=);
  margin: auto;
  cursor: pointer;
}
